import { Contract, ContractValue } from "@interfaces";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { actions as contractActions } from "./contract.actions";

export interface ContractState extends EntityState<Contract> {
	error: any;
	loading: boolean;
	contractValues: { [key: string]: ContractValue[] };
}
const selectId = (c: Contract): string => c.id;

export const adapter: EntityAdapter<Contract> = createEntityAdapter<Contract>({
	selectId: selectId,
	sortComparer: false
});
const initialState: ContractState = adapter.getInitialState({
	error: null,
	loading: false,
	contractValues: {}
});

export const contractReducer = createReducer(
	initialState,
	on(contractActions.loadContracts, state => ({ ...state, loading: true })),
	on(contractActions.loadContractsSuccess, (state, { contracts }) => {
		state = { ...state, loading: false };
		return adapter.upsertMany(contracts, state);
	}),
	on(contractActions.updateContractSuccess, (state, { contract }) =>
		adapter.upsertOne(contract, state)
	),
	on(contractActions.loadContractValues, state => ({
		...state,
		loading: true
	})),
	on(contractActions.loadContractValuesSuccess, (state, { update }) => {
		state = { ...state, loading: false };
		const values = update.changes.ContractValues as ContractValue[];
		const contractValues = { ...state.contractValues, [update.id]: values };

		return { ...state, contractValues };
	}),
	on(contractActions.updateContractsActiveSuccess, (state, { accounts }) => {
		const contracts = adapter.getSelectors().selectAll(state);

		const updatedContracts = contracts.map(contract => {
			const account = accounts.find(a => a.id === contract.contractBIId);
			return { ...contract, isActiveContract: account?.display || false };
		});

		return adapter.upsertMany(updatedContracts, state);
	})
);
