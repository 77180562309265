import { BankAccount, Contract } from "@interfaces";
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export enum ContractActionTypes {
	LOAD_CONTRACTS = "[Contract] Load Contracts",
	LOAD_CONTRACTS_SUCCESS = "[Contract] Load Contracts Success",
	LOAD_CONTRACTS_FAILURE = "[Contract] Load Contracts Failure",
	UPDATE_CONTRACT = "[Contract] Update Contract",
	UPDATE_CONTRACT_SUCCESS = "[Contract] Update Contract Success",
	UPDATE_CONTRACT_FAILURE = "[Contract] Update Contract Failure",
	LOAD_CONTRACT_VALUES = "[Contract] Load Contract Values",
	LOAD_CONTRACT_VALUES_SUCCESS = "[Contract] Load Contract Values Success",
	LOAD_CONTRACT_VALUES_FAILURE = "[Contract] Load Contract Values Failure",
	UPDATE_CONTRACTS_ACTIVE = "[Contract] Update Contract Active",
	UPDATE_CONTRACTS_ACTIVE_SUCCESS = "[Contract] Update Contract Active Success",
	UPDATE_CONTRACTS_ACTIVE_FAILURE = "[Contract] Update Contract Active Failure"
}

export const actions = {
	// LOAD CONTRACTS
	loadContracts: createAction(ContractActionTypes.LOAD_CONTRACTS),
	loadContractsSuccess: createAction(
		ContractActionTypes.LOAD_CONTRACTS_SUCCESS,
		props<{ contracts: Contract[] }>()
	),
	loadContractsFailure: createAction(
		ContractActionTypes.LOAD_CONTRACTS_FAILURE,
		props<{ error: any }>()
	),
	// UPDATE CONTRACT
	updateContract: createAction(
		ContractActionTypes.UPDATE_CONTRACT,
		props<{ id: string }>()
	),
	updateContractSuccess: createAction(
		ContractActionTypes.UPDATE_CONTRACT_SUCCESS,
		props<{ contract: Contract }>()
	),
	updateContractFailure: createAction(
		ContractActionTypes.UPDATE_CONTRACT_FAILURE,
		props<{ error: any }>()
	),
	// LOAD CONTRACT VALUES
	loadContractValues: createAction(
		ContractActionTypes.LOAD_CONTRACT_VALUES,
		props<{ id: string; limit: number }>()
	),
	loadContractValuesSuccess: createAction(
		ContractActionTypes.LOAD_CONTRACT_VALUES_SUCCESS,
		props<{ update: Update<Contract> }>()
	),
	loadContractValuesFailure: createAction(
		ContractActionTypes.LOAD_CONTRACT_VALUES_FAILURE,
		props<{ error: any }>()
	),
	// UPDATE CONTRACTS ACTIVE
	updateContractsActive: createAction(
		ContractActionTypes.UPDATE_CONTRACTS_ACTIVE
	),
	updateContractsActiveSuccess: createAction(
		ContractActionTypes.UPDATE_CONTRACTS_ACTIVE_SUCCESS,
		props<{ accounts: BankAccount[] }>()
	),
	updateContractsActiveFailure: createAction(
		ContractActionTypes.UPDATE_CONTRACTS_ACTIVE_FAILURE,
		props<{ error: any }>()
	)
};
