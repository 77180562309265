import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@environments/environment";
import { BankAccount, BoConnection, Connection } from "@interfaces";
import { BehaviorSubject, Observable } from "rxjs";
import {
	distinctUntilChanged,
	filter,
	map,
	shareReplay,
	switchMap,
	tap
} from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class BudgetinsightService {
	private HTTP = inject(HttpClient);
	private css = "color:#8e44ad;font-weight:bold";
	// private publicKey: any = environment.biJWK;
	private httpOptions: any;

	/*****************************************************
	 * WEBVIEW
	 *****************************************************/

	public openWebView() {
		console.log("this.token: ", this.token);
		if (this.token) {
			this.openWebViewManage();
		} else {
			this.openWeebViewAnonymously();
		}
	}

	private openWebViewManage() {
		const path = "auth/webview/manage";
		const redirectURI = encodeURIComponent(window.location.href);
		const client_id = environment.biClientId;
		const sufix = "connector_capabilities=bank";

		this.getTempCode().subscribe(code => {
			const url = `${environment.biAPIUrl}/${path}?redirect_uri=${redirectURI}&client_id=${client_id}&code=${code}&${sufix}`;
			window.location.href = url;
		});
	}

	private openWeebViewAnonymously() {
		const redirectURI = encodeURIComponent(window.location.href);
		const url = `${environment.biAPIUrl}/auth/webview/connect?client_id=${environment.biClientId}&redirect_uri=${redirectURI}`;
		window.location.href = url;
	}

	/*****************************************************
	 * TOKEN
	 *****************************************************/
	private _token: BehaviorSubject<string | null> = new BehaviorSubject<
		string | null
	>(null);
	public get token(): string | null {
		return this._token.getValue();
	}
	public set token(token: string | null) {
		const headers = new HttpHeaders().set(
			"Authorization",
			token ? `Bearer ${token}` : ""
		);
		this.httpOptions = { headers };
		this._token.next(token);
	}
	public token$: Observable<string | null> = this._token.asObservable().pipe(
		distinctUntilChanged(),
		filter(token => !!token)
	);

	public getTempCode(): Observable<string> {
		return this.mainGET(
			`/auth/token/code?type=singleAccess`,
			`singleAccess`
		).pipe(map(r => r.code));
	}
	public getPermanentTokenFromTemp(code?: string): Observable<string> {
		const data = {
			grant_type: "authorization_code",
			client_id: environment.biClientId,
			client_secret: environment.biClientSecret,
			code: !!code ? code : this.token
		};

		return this.HTTP.post<any>(
			`${environment.biAPIUrl}/auth/token/access`,
			data
		).pipe(
			map(({ access_token }) => access_token),
			tap(token =>
				console.log(`%cBI get permanent token : ${token}`, this.css)
			),
			// update token
			tap(token => (this.token = token))
		);
	}

	/*****************************************************
	 * REFRESH
	 *****************************************************/

	private readonly _refresh: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	public readonly refresh$: Observable<number> = this._refresh
		.asObservable()
		.pipe(
			filter(val => val !== 0),
			tap(r => console.log("%cREFRESH [BI]", this.css))
		);

	public refresh() {
		this._refresh.next(this._refresh.getValue() + 1);
	}

	/*****************************************************
	 * COMMONS
	 *****************************************************/

	private OPTION$ = this.token$.pipe(
		filter(token => !!token),
		map(() => this.httpOptions)
	);
	private mainGET(path: string, name?: string) {
		const URL = `${environment.biAPIUrl}/${path}`;

		return this.OPTION$.pipe(
			switchMap(OPTIONS => this.HTTP.get<any>(URL, OPTIONS)),
			//first(),
			map((result: any) => result),
			tap(result => {
				console.groupCollapsed(
					`%cBI GET ${name?.toUpperCase() ?? path}`,
					this.css
				);
				console.log(`${path}`);
				console.log(result);
				console.groupEnd();
			}),

			shareReplay()
		);
	}

	/*****************************************************
	 * ACCOUNTS
	 *****************************************************/
	public allAccount$: Observable<BankAccount[]> = this.refresh$.pipe(
		tap(data => console.log("refresh allAccounts")),
		switchMap(() => this.mainGET(`users/me/accounts`, `Account list`)),
		map(({ accounts }) => accounts)
	);

	/*****************************************************
	 * CONNECTIONS
	 *****************************************************/
	public getAllConnections = this.mainGET(
		`users/me/connections?expand=accounts,connector`, //?expand=accounts,connector
		`ALL connections list`
	).pipe(
		map(r => r.connections),
		map((connections: Connection[]) =>
			connections.map(
				c =>
					({
						accounts: c.accounts,
						id: (c.id || "").toString(),
						connectionId: c.id, // ??
						error: c.error,
						Bank: {
							id: (c.connector?.id ?? "").toString(),
							name: c.connector?.name,
							slug: c.connector?.slug,
							uuid: c.connector?.uuid
						}
					} as BoConnection)
			)
		)
	);
	public getConnectionById = (id: string | number) =>
		this.mainGET(
			`users/me/connections/${id}`, //?expand=accounts,connector
			`ALL connections list`
		);
}
