import { Component, input } from "@angular/core";

@Component({
	selector: "loading-box",
	standalone: true,
	template: `
		<ng-content />
		@if(loader()){
		<span class="loader primary"></span>}
	`,
	styles: [
		`
			@import "utils";
			:host {
				padding: 4em 2em 2em;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 2em;

				min-height: 10em;
				height: 100%;
				font-size: 1em;
				color: $default-primary;

				p {
					color: $default-primary;
					text-transform: uppercase;
					opacity: 0.6;
				}

				&.cover {
					position: absolute;
					background-color: #fff;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 100;
					min-height: 0;
				}
			}
		`
	]
})
export class LoadingBoxComponent {
	loader = input<boolean>(true);
}
