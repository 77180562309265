import { Component, computed, input } from "@angular/core";
import { environment } from "@src/environments/environment";
import { Bank } from "../interface";

@Component({
	selector: "bank-logo",
	standalone: true,
	template: `
		<img
			[src]="bankLogoURL()"
			[alt]="bank()?.name"
			onError="this.src='assets/images/logo-banque/logo-bank-fallback.svg'"
		/>
	`,
	styles: [
		`
			:host {
				display: contents;
			}
			img {
				max-height: 100%;
				max-width: 100%;
			}
		`
	]
})
/**
 * Represents a component for displaying a bank logo.
 */
export class BankLogoComponent {
	defaultLogoURL = "assets/images/logo-banque/logo-bank-fallback.svg";
	bank = input<Bank>();

	/**
	 * Gets the URL of the bank logo.
	 * If the bank has a UUID, it returns the URL of the bank logo with the UUID.
	 * Otherwise, it returns the default logo URL.
	 * @returns The URL of the bank logo.
	 */
	bankLogoURL = computed(() => {
		const uuid = this.bank()?.uuid;
		return uuid
			? `${environment.biAPIUrl}/logos/${uuid}-thumbnail.webp`
			: this.defaultLogoURL;
	});
}
