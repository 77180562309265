import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CardInfo, CardMarketing } from "../../interface";
import { TipState } from "./tip.reducers";
export const selectTipState = createFeatureSelector<TipState>("tips");
export const selectTips = createSelector(
	selectTipState,
	(state: TipState) => state.tips
);

export const selectMArketingTips = createSelector(
	selectTipState,
	(state: TipState) =>
		state.tips.filter(
			tip => tip.cardContentType === "marketing"
		) as CardMarketing[]
);

export const selectInfoTips = createSelector(
	selectTipState,
	(state: TipState) =>
		state.tips.filter(tip => tip.cardContentType === "info") as CardInfo[]
);

export const selectInfoTipsByType = (type: "classique" | "mantra") =>
	createSelector(
		selectTipState,
		(state: TipState) =>
			state.tips.filter(
				tip => tip.cardContentType === "info" && tip.type === type
			) as CardInfo[]
	);
