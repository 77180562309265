import { BoConnection } from "@interfaces";
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export enum ConnectionActionTypes {
	LOAD_CONNECTIONS = "[connection] Load connections",
	LOAD_CONNECTIONS_SUCCESS = "[connection] Load connections Success",
	LOAD_CONNECTIONS_FAILURE = "[connection] Load connections Failure",
	UPDATE_CONNECTION = "[connection] Update connection",
	UPDATE_CONNECTION_SUCCESS = "[connection] Update connection Success",
	UPDATE_CONNECTION_FAILURE = "[connection] Update connection Failure"
}

export const actions = {
	// LOAD CONNECTIONS
	loadConnections: createAction(ConnectionActionTypes.LOAD_CONNECTIONS),
	loadConnectionsSuccess: createAction(
		ConnectionActionTypes.LOAD_CONNECTIONS_SUCCESS,
		props<{ connections: BoConnection[] }>()
	),
	loadConnectionsFailure: createAction(
		ConnectionActionTypes.LOAD_CONNECTIONS_FAILURE,
		props<{ error: any }>()
	),

	// UPDATE CONNECTION
	updateConnection: createAction(
		ConnectionActionTypes.UPDATE_CONNECTION,
		props<{ id: string }>()
	),
	updateConnectionSuccess: createAction(
		ConnectionActionTypes.UPDATE_CONNECTION_SUCCESS,
		props<{ update: Update<BoConnection> }>()
	),
	updateConnectionFailure: createAction(
		ConnectionActionTypes.UPDATE_CONNECTION_FAILURE,
		props<{ error: any }>()
	)
};
