const strapiRoot = "http://localhost:1337";

export const environment = {
	production: true,
	envName: "mvp",
	// apiUrl: 'https://gql-staging.k8s.boost-epargne.com/',
	apiUrl: "https://gql-mvp.k8s.boost-epargne.com/graphql",
	biAPIUrl: "https://boostepargne-staging.biapi.pro/2.0",
	strapiRoot,
	strapiUrl: `${strapiRoot}/graphql`,
	biClientId: 67922755,
	biClientSecret: "94fe1oL3nSNC1D6WIghTCTaNu9QER5zA",
	biJWK: {
		e: "AQAB",
		kid: "q6fdlNUYXZVtVqCxhLW_M_K7UkCHn00tTX-MRdmD1Ls",
		kty: "RSA",
		n: "pLtiDVI7SR5ejaDSlcRIluj-yp4S7WIcKqk6Q0nmY2POGQfRT-uOjGdZ8vGb9VmjrNzGunuGcB4cBM0cwPYEnY7qsGZg2wTDRvUrdSRmA9CoIkgWBaUob_Fpn759gxKJx_kRcFEvEy-6LuOB72jYwF0ObzbNebmlhox4bL0kUvBCRFMlBkrzZ3hTmQc55DHG0ZMcJte1BTKZ2DuxSFFHiWu9o3ujdAmR8XRphjIYiZcBNG42I6ng5Tb-nyJD8X4PsTKS7-umwq4CzvxL3MiOTPwhuYW-61y1QiytSx0DyPdmOMbSaqqe4sUWlcUhAOHVwvcT2ZWMEs32_vdo5yuzmw"
	}
};
