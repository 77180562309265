import { Injectable } from "@angular/core";
import { User } from "@interfaces";
import { getMe, getProfile } from "@queries/User";
import { objectDiff } from "@services/utils/utils.service";
import { Apollo, ApolloBase, gql } from "apollo-angular";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
export const CSP_LIST: string[] = [
	"Agriculteurs exploitants",
	"Artisans, commerçants et chefs d’entreprise",
	"Cadres et professions intellectuelles supérieures",
	"Professions Intermédiaires",
	"Employés",
	"Ouvriers"
];
export const AGES_LIST: string[] = ["18-25", "25-39", "40-65", "65+"];

@Injectable({ providedIn: "root" })
export class UserService {
	private css = "color:#2980b9;font-weight:bold";

	private apollo: ApolloBase;
	constructor(private apolloProvider: Apollo) {
		this.apollo = this.apolloProvider.use("mainAPI");
	}

	public init(): Observable<string> {
		console.log("%cinit USER service", this.css);
		return this.getProfile().pipe(
			map(({ data }) => data?.me),
			tap(profile => this._currentUser.next(profile)),
			map(profile => profile?.biToken)
		);
	}
	private readonly _currentUser: BehaviorSubject<User> =
		new BehaviorSubject<User>({} as User);

	public profile$: Observable<User> = this._currentUser.asObservable().pipe(
		filter(profile => !!profile),
		tap(me => console.log("%cprofile  : %O", this.css, me))
		// shareReplay()
	);

	public get profile(): User {
		return this._currentUser.getValue();
	}
	public getId(): string {
		return localStorage.getItem("userId") ?? "";
	}

	public getOid(): string {
		return localStorage.getItem("userOid") ?? "";
	}

	public getMe() {
		return this.apollo.watchQuery<any>({
			query: getMe,
			fetchPolicy: "network-only"
		}).valueChanges;
	}

	public getProfile() {
		return this.apollo.watchQuery<any>({
			query: getProfile,
			fetchPolicy: "network-only"
		}).valueChanges;
	}

	public editUser(user: Partial<User>): Observable<any> {
		const dataRef = this._currentUser.getValue();
		const differencies = objectDiff(user, dataRef).length > 0;

		if (!dataRef || !user || !differencies) return of(null);

		const { id, nickname, email, csp, age } = dataRef;
		const dataWithChanges = { id, nickname, email, csp, age, ...user };

		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser(
						$id: ID!
						$nickname: String
						$email: String
						$password: String
						$csp: String
						$age: String
					) {
						saveUser(
							id: $id
							nickname: $nickname
							email: $email
							password: $password
							csp: $csp
							age: $age
						)
					}
				`,
				variables: {
					...dataWithChanges
				}
			})
			.pipe(first());
	}
	// TODO :  remove ?
	public edit(params: {
		id: string;
		nickname: string;
		email: string;
		password: string;
		csp: string;
		age: string;
	}) {
		console.log(
			"UserService > edit : ",
			params.id,
			params.nickname,
			params.email,
			params.password,
			params.age,
			params.csp
		);
		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser(
						$id: ID!
						$nickname: String
						$email: String
						$password: String
						$csp: String
						$age: String
					) {
						saveUser(
							id: $id
							nickname: $nickname
							email: $email
							password: $password
							csp: $csp
							age: $age
						)
					}
				`,
				variables: {
					id: params.id,
					nickname: params.nickname,
					email: params.email,
					password: params.password,
					csp: params.csp,
					age: params.age
				}
			})
			.pipe(
				map(result => {
					console.log(result);
					return result;
				})
			);
	}

	public editCspAge(params: { id: string | null; csp: string; age: string }) {
		console.log("UserService > edit : ", params.id, params.age, params.csp);
		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser($id: ID!, $csp: String, $age: String) {
						saveUser(id: $id, csp: $csp, age: $age)
					}
				`,
				variables: {
					id: params.id,
					csp: params.csp,
					age: params.age
				}
			})
			.pipe(
				map(result => {
					console.log(result);
					//		this.biService.refresh();
					return result;
				})
			);
	}

	public impersonate(email: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation impersonate($email: String!) {
						impersonate(email: $email) {
							token
						}
					}
				`,
				variables: {
					email: email
				}
			})
			.pipe(
				map((result: any) => {
					localStorage.setItem("currentUser", result.data.impersonate.token);
					console.log(result);
					return result;
				})
			);
	}
}
