import { Injectable, inject } from "@angular/core";
import { Dictionary } from "@ngrx/entity";
import { Store, select } from "@ngrx/store";
import * as contractSelectors from "@shared/store/contracts/contract.selectors";
import {
	BankAccount,
	BoConnection,
	CardInfo,
	CardMarketing,
	CardTip,
	ConnectionState,
	ConnectionsWithIssue,
	Contract,
	ContractDisplay,
	ContractValue,
	ErrorIconsResume
} from "@src/app/shared/interface";
import {
	ConnectionActions,
	ContractActions,
	TipActions
} from "@src/app/shared/store/actions";

import {
	connectionSelectors,
	tipSelectors
} from "@src/app/shared/store/selectors";
import { Observable, from, map, switchMap, tap } from "rxjs";
import { errorIconsResume } from "../utils/error-messages";
@Injectable({ providedIn: "root" })
export class StoreService {
	private store$ = inject(Store);
	private css = "color:#fb7e41;font-weight:bold";

	/*****************************************************
	 * TIPS
	 *****************************************************/
	public tipsList$: Observable<CardTip[]> = this.store$.pipe(
		select(tipSelectors.selectTips)
	);
	public marketingTipsList$: Observable<CardMarketing[]> = this.store$.pipe(
		select(tipSelectors.selectMArketingTips)
	);
	public infoTipsList$: Observable<CardInfo[]> = this.store$.pipe(
		select(tipSelectors.selectInfoTips)
	);
	public infoTipsListByType$ = (
		type: "classique" | "mantra" | undefined
	): Observable<CardTip[]> => {
		if (!type) return from([]) as Observable<CardTip[]>;
		return this.store$.pipe(select(tipSelectors.selectInfoTipsByType(type)));
	};

	/*****************************************************
	 * CONTRACTS
	 *****************************************************/
	public filterContractList$: Observable<Contract[]> = this.store$.pipe(
		select(contractSelectors.displayContracts)
	);
	public contractDisplayList$: Observable<ContractDisplay[]> =
		this.filterContractList$.pipe(
			switchMap(contracts =>
				this.connection$.pipe(map(connections => ({ contracts, connections })))
			),
			map(({ contracts, connections }) => {
				return contracts.map((contract: Contract) => {
					const connection = connections[contract.connectionId];
					return {
						...contract,
						Bank: contract?.Bank,
						error: connection?.error
					};
				}) as ContractDisplay[];
			})
		);

	public contractsLoading$: Observable<boolean> = this.store$.pipe(
		select(contractSelectors.contractsLoading)
	);
	/*****************************************************
	 * CONTRACT by ID
	 *****************************************************/

	public contractsByConnectionId = (id: string): Observable<Contract[]> =>
		this.store$.pipe(select(contractSelectors.contractsByConnectionId(id)));

	public contractById = (id: string): Observable<Contract | undefined> =>
		this.store$.pipe(select(contractSelectors.contractById(id)));
	public contractValuesById = (id: string): Observable<ContractValue[]> =>
		this.store$.pipe(select(contractSelectors.contractValuesById(id)));

	public displayContractById = (
		id: string
	): Observable<ContractDisplay | undefined> =>
		this.contractDisplayList$.pipe(
			map(contracts => contracts.filter(contract => contract.id === id)[0])
		);

	/*****************************************************
	 * CONNECTIONS
	 *****************************************************/

	public connection$: Observable<Dictionary<BoConnection>> = this.store$.pipe(
		select(connectionSelectors.selectEntities)
	);
	public connectionsList$: Observable<BoConnection[]> = this.store$.pipe(
		select(connectionSelectors.selectAll)
	);

	public filterAccountList$: Observable<BankAccount[]> = this.store$.pipe(
		select(connectionSelectors.displayAccounts)
	);

	public connectionsWithIssue$: Observable<BoConnection[]> = this.store$.pipe(
		select(connectionSelectors.connectionsWithError)
	);

	public connectionIssuesIcon$: Observable<ErrorIconsResume[]> =
		this.connectionsWithIssue$.pipe(
			map((connections: ConnectionsWithIssue[]) =>
				connections.map(({ error }) => error as ConnectionState)
			),
			map((errors: ConnectionState[]) => errorIconsResume([...errors]))
		);

	public connectionsLoading$: Observable<boolean> = this.store$.pipe(
		select(connectionSelectors.connectionsLoading)
	);

	public connectionById = (
		id: string | number
	): Observable<BoConnection | undefined> =>
		this.store$.pipe(select(connectionSelectors.connectionById(id)));

	/*****************************************************
	 * ACTIONS
	 *****************************************************/

	public loadTips = () => {
		this.store$.dispatch(TipActions.actions.loadTips());
	};
	public loadContracts = () => {
		this.store$.dispatch(ContractActions.actions.loadContracts());
	};
	public updateContractsActive = () => {
		this.store$.dispatch(ContractActions.actions.updateContractsActive());
	};

	public loadConnections = () => {
		this.store$.dispatch(ConnectionActions.actions.loadConnections());
	};

	public loadContractValues = (id: string, limit: number) => {
		this.store$.dispatch(
			ContractActions.actions.loadContractValues({
				id,
				limit
			})
		);
	};

	public updateContract = (id: string) => {
		this.store$.dispatch(
			ContractActions.actions.updateContract({
				id
			})
		);
	};

	/*****************************************************
	 * MIX
	 *****************************************************/

	public diffAccountsContract$: Observable<BankAccount[]> =
		this.filterAccountList$.pipe(
			switchMap((accounts: BankAccount[]) =>
				this.filterContractList$.pipe(
					map((contracts: Contract[]) => ({ accounts, contracts }))
				)
			),
			tap(({ accounts, contracts }) => console.log({ accounts, contracts })),
			map(({ accounts, contracts }) =>
				accounts.filter(
					account =>
						!contracts.some(contract => contract.contractBIId === account.id)
				)
			),
			tap(accounts => console.log({ accounts }))
		);
}
