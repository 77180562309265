import { ConnectionState, ErrorIconsResume } from "@interfaces";

export function getBIErrorMessage(code: string | null, bankName?: string) {
	let errorMessage = "";
	switch (code) {
		case "wrongpass":
			errorMessage = bankName
				? `Vous devez saisir à nouveau votre identifiant et mot de passe de connection
      à votre espace client ${bankName}.`
				: `Le mot de passe n'est pas correct`;
			break;
		case "websiteUnavailable":
			errorMessage = `Le site de l'établissement est en maintenance`;
			break;
		case "actionNeeded":
			errorMessage = bankName
				? `Vous devez vous connecter à votre espace client ${bankName} pour pouvoir réactiver la synchronisation (Validation de CGU, ...)`
				: `Une action de votre part est nécessaire sur le site de l'établissement pour pouvoir poursuivre`;
			break;
		case "additionalInformationNeeded":
			errorMessage = `Vous devez saisir un code envoyé pas la banque`;
			break;
		case "bug":
			errorMessage = `Un problème est survenu et nécessite l'intervention de notre partenaire Budget Insight`;
			break;
		case "passwordExpired":
			errorMessage = `Votre mot de passe a expiré, il doit être réinitialisé sur le site de votre banque.`;
			break;
		case "rateLimiting":
			errorMessage = `Votre banque est temporairement inaccessible, veuillez réessayer plus tard.`;
			break;
		case "SCARequired":
			errorMessage = bankName
				? `Vous devez vous connecter à votre espace client ${bankName} pour pouvoir réactiver la synchronisation.`
				: `Vous devez vous connecter à votre espace client pour pouvoir réactiver la synchronisation.`;
			break;
		default:
			errorMessage = `Une erreur s'est produite avec le site de l'établissement`;
	}
	return errorMessage;
}

/*
SCARequired	An SCA process must be performed to resume the synchronization process.
webauthRequired	A web-based authentication process is required using the /webauth endpoint.
additionalInformationNeeded	Additional information is needed to resume synchronization, such as an OTP. Connections in this state have a fields property.
decoupled	User validation is required on a third-party app or device (ex: digital key).
validating	User validation is being processed on our side. This state is temporary.
actionNeeded	An action is needed on the website by the user, synchronization is blocked.
passwordExpired	The password has expired and needs to be changed by the user before the synchronization can be retried.
wrongpass	The authentication on website has failed and new credentials must be obtained from the user. Connections in this state have a fields property.
rateLimiting	The target website or API is temporarily blocking synchronizations due to rate limiting.
websiteUnavailable	The connector website or API is unavailable.
bug	An internal error has occurred during the synchronization.


*/

const BI_ERRORS_ICONS: {
	icon: string;
	color: string;
	errortypes: ConnectionState[];
}[] = [
	{
		icon: "feedback",
		color: "danger",
		errortypes: [
			"SCARequired",
			"webauthRequired",
			"additionalInformationNeeded",
			"decoupled",
			"validating"
		]
	},
	{
		icon: "edit_calendar",
		color: "info",
		errortypes: [
			"actionNeeded",
			"passwordExpired",
			"wrongpass",
			"rateLimiting",
			"websiteUnavailable",
			"bug"
		]
	}
];

export const getErrorIcon = (errorType: ConnectionState): string =>
	BI_ERRORS_ICONS.find(({ errortypes }) => errortypes.includes(errorType))
		?.icon ?? "";

export const errorTheme = (
	errorType: string,
	bankName?: string
): {
	type: ConnectionState;
	message: string;
	icon?: string;
	color?: string;
} => ({
	type: errorType as ConnectionState,
	message: getBIErrorMessage(errorType, bankName),
	...BI_ERRORS_ICONS.find(({ errortypes }) =>
		errortypes.includes(errorType as ConnectionState)
	)
});

// export const errorThemes = (
// 	errorTypes: ConnectionState[],
// 	bankName?: string
// ): { type: ConnectionState; message: string; icon: string; color: string }[] =>
// 	errorTypes.map(errorType => errorTheme(errorType, bankName));

export const errorIconsResume = (
	errorTypes: ConnectionState[]
): ErrorIconsResume[] =>
	errorTypes.reduce((acc, curErrorType) => {
		const details = BI_ERRORS_ICONS.find(({ errortypes }) =>
			errortypes.includes(curErrorType)
		);
		const typeIndex = acc.findIndex(({ icon }) => icon === details?.icon);

		if (typeIndex >= 0) acc[typeIndex].count++;
		else acc.push({ ...details, count: 1 } as ErrorIconsResume);

		return acc;
	}, [] as ErrorIconsResume[]);
