import { createAction, props } from "@ngrx/store";
import { CardTip } from "../../interface";

export enum TipActionTypes {
	LOAD_TIPS = "[Tip] Load Tips",
	LOAD_TIPS_SUCCESS = "[Tip] Load Tips Success",
	LOAD_TIPS_FAILURE = "[Tip] Load Tips Failure"
}

export const actions = {
	loadTips: createAction(TipActionTypes.LOAD_TIPS),
	loadTipsSuccess: createAction(
		TipActionTypes.LOAD_TIPS_SUCCESS,
		props<{ tips: CardTip[] }>()
	),
	loadTipsFailure: createAction(
		TipActionTypes.LOAD_TIPS_FAILURE,
		props<{ error: any }>()
	)
};
