import { AccountTypeName, BankAccount, BoConnection } from "@interfaces";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { filterAccountType } from "@src/app/core/services/utils/utils.service";
import { ConnectionState, adapter } from "./connection.reducers";

// connection selectors
const ConnectionSelectors =
	createFeatureSelector<ConnectionState>("connections");

export const { selectIds, selectEntities, selectAll, selectTotal } =
	adapter.getSelectors(ConnectionSelectors);

export const connectionsWithError = createSelector(
	selectAll,
	(connections: BoConnection[]) =>
		connections.filter(
			(connection: BoConnection) => !!connection.error //&& connection.error.length > 0
		)
);
export const connectionById = (id: string | number) =>
	createSelector(selectEntities, connections => connections[id]);

export const displayAccounts = createSelector(
	selectAll,
	(connections: BoConnection[]) =>
		connections
			.flatMap((connection: BoConnection) => connection.accounts ?? [])
			.filter(
				(account: BankAccount) =>
					// account?.display &&
					!account?.disabled &&
					account?.type &&
					filterAccountType(account.type as AccountTypeName)
			)
);

export const connectionsLoading = createSelector(
	ConnectionSelectors,
	(state: ConnectionState) => state.loading
);

export const connectionsError = createSelector(
	ConnectionSelectors,
	(state: ConnectionState) => state.error
);
