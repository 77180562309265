import { environment } from "@environments/environment";
import { AccountType, AccountTypeName } from "@interfaces";

const PRIMARY_COLOR = "#4675b8";
/**
 * Array of colors used to represent different levels of risk.
 */
const RISK_COLORS: string[] = [
	"#2095F2",
	"#01BBD3",
	"#8BC24B",
	"#FFC10A",
	"#FF5723",
	"#C02D36",
	"#571929"
];
/**
 * Array of color codes used for comparison.
 */
export const COMPARE_COLORS: string[] = [
	"#405a99",
	"#9ad251",
	"#f87c51",
	"#d84355",
	"#ffcb00",
	"#32bde9",
	"#2c9e07",
	"#5400c1"
];

/**
 * List of account types to display.
 */
export const ACCOUNT_TYPES_TO_DISPLAY: AccountTypeName[] = [
	"lifeinsurance",
	"perco",
	"pee",
	"savings",
	"unknown",
	"market",
	"article83",
	"madelin",
	"pea",
	"capitalisation",
	"perp",
	"per"
	// "checking"
];

/**
 * List of account types for salarial accounts.
 */
export const SALARIAL_ACCOUNT_TYPES: AccountTypeName[] = ["perco", "pee"];

/**
 * Filter account type based on its name or type.
 * @param accountType - The account type to filter.
 * @returns A boolean indicating whether the account type should be displayed or not.
 */
export const filterAccountType = (
	accountType: AccountTypeName | AccountType
): boolean => {
	const accountTypeName =
		typeof accountType === "string" ? accountType : accountType.name;

	return ACCOUNT_TYPES_TO_DISPLAY.includes(accountTypeName);
	// (https://docs.budget-insight.com/reference/bank-accounts#response-bankaccount-object)
	// BankAccount.type est AccountType selon la doc
	// mais dans la réponse de l'api, c'est un string (AccountTypeName)
	// Je prévois les 2 en cas d'évolution de l'api
};

/**
 * Filters an account type to check if it is a salarial account type.
 * @param accountType - The account type to filter.
 * @returns A boolean indicating whether the account type is a salarial account type or not.
 */
export const filterSalarialAccountType = (
	accountType: AccountTypeName | AccountType
): boolean => {
	const accountTypeName =
		typeof accountType === "string" ? accountType : accountType.name;
	return SALARIAL_ACCOUNT_TYPES.includes(accountTypeName);
};

/**
 * Returns the color associated with a given risk level.
 * @param risk - The risk level to get the color for.
 * @returns The color associated with the given risk level.
 */
export function getRiskColor(risk: any) {
	if (
		!risk ||
		risk === null ||
		risk === 0 ||
		risk === "FE" ||
		Number.isNaN(risk)
	) {
		return PRIMARY_COLOR;
	} else return RISK_COLORS[risk - 1];
}

/**
 * Returns the RGBA color code for a given risk level.
 * @param risk - The risk level.
 * @returns The RGBA color code.
 */
export function getRiskColorRgba(risk: any) {
	if (
		!risk ||
		risk === null ||
		risk === 0 ||
		risk === "FE" ||
		Number.isNaN(risk)
	) {
		return "rgba(0,0,0,1)";
	} else return `rgba(${hexToRgb(RISK_COLORS[risk - 1]).join(",")},1)`;
}
/**
 * Applies a shade to the color associated with the given risk level.
 * @param risk - The risk level.
 * @param amt - The amount of shade to apply.
 * @returns The shaded color.
 */
export function riskShade(risk: any, amt: number) {
	return colorShade(getRiskColor(risk), amt);
}
export function primaryShade(amt: number) {
	return colorShade(PRIMARY_COLOR, amt);
}

/**
 * Returns a color with the specified shade.
 * @param col - The color to modify.
 * @param amt - The amount of shade to add or remove.
 * @returns The modified color.
 */
export function colorShade(col: any, amt: any) {
	col = col.slice(1);

	const num = parseInt(col, 16);

	let r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	let b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	let g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return "#" + (g | (b << 8) | (r << 16)).toString(16);
}

/**
 * Converts a hexadecimal color code to an RGB array.
 * @param hex - The hexadecimal color code to convert.
 * @returns An array containing the red, green, and blue values of the converted color.
 */
export function hexToRgb(hex: string) {
	const rgb = hex
		.replace(
			/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
			(m, r, g, b) => "#" + r + r + g + g + b + b
		)
		.substring(1)
		.match(/.{2}/g);
	return rgb ? rgb.map(x => parseInt(x, 16)) : [0, 0, 0];
}

/**
 * Converts RGB color values to hexadecimal format.
 * @param r - The red color value (0-255).
 * @param g - The green color value (0-255).
 * @param b - The blue color value (0-255).
 * @returns The hexadecimal color string.
 */
export function rgbToHex(r: number, g: number, b: number) {
	return (
		"#" +
		[r, g, b]
			.map(x => {
				const hex = x.toString(16);
				return hex.length === 1 ? "0" + hex : hex;
			})
			.join("")
	);
}

/**
 * Convert a value to an integer.
 * @param val - The value to convert.
 * @returns The integer value of the input.
 */
export const toInt = (val: number | string): number =>
	typeof val === "string" ? parseInt(val) : val;

/**
 * Format a number to display it as a string with a space separator for thousands and a comma separator for decimals, followed by the euro symbol.
 * @param x - The number to format.
 * @returns The formatted string.
 */
export const displayValue = (x: number): string => {
	const roundValue = x.toFixed(2);
	const parts = roundValue.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
	return parts.join(",&nbsp;") + "&nbsp;€";
};

/**
 * Format a string for search by removing diacritics, trimming and converting to lowercase.
 * @param str - The string to format.
 * @returns The formatted string.
 */
export const formatStringForSearch = (str: string): string => {
	return str
		.trim()
		.toLowerCase()
		.normalize("NFD")
		.replace(/\p{Diacritic}/gu, "");
};

/**
 * Sorts an array of objects by a given key.
 * @param arr - The array to sort.
 * @param key - The key to sort the array by.
 * @returns A new sorted array.
 */
export const sortBy = (arr: any[], key: string) => {
	const sortBy = (filterKey: string | number) => (a: any, b: any) =>
		a[filterKey] > b[filterKey] ? 1 : b[filterKey] > a[filterKey] ? -1 : 0;
	return arr?.concat().sort(sortBy(key)) || [];
};

/**
 * Returns an array of keys that have different values between newData and dataRef.
 * @param newData - The new data object to compare.
 * @param dataRef - The reference data object to compare.
 * @returns An array of keys that have different values between newData and dataRef.
 */
export const objectDiff = (newData: any, dataRef: any): string[] => {
	const diffs = Object.keys(newData).filter(
		key => newData[key] !== dataRef[key]
	);

	const notArrdiffs = diffs.filter(key => !Array.isArray(newData[key]));
	const arrsInDiffs = diffs.filter(key => Array.isArray(newData[key]));
	if (arrsInDiffs) {
		const arrDiffs: string[] = arrsInDiffs.filter(key => {
			if (!newData[key] && !dataRef[key]) return false;
			if (!newData[key] || !dataRef[key]) return true;
			if (newData[key].length !== dataRef[key].length) return true;
			return newData[key].some((v: any, i: number) => v !== dataRef[key][i]);
		});

		return [...notArrdiffs, ...arrDiffs];
	}

	return notArrdiffs;
};

/**
 * Removes invalid parameters from an object.
 * @param obj - The object to clean.
 * @returns The cleaned object.
 */
export const cleanDataFrom = (obj: any): any => {
	// delete invalid params in obj
	return Object.keys(obj)
		.filter(key => !!obj[key])
		.reduce((res: any, key) => {
			res[key] = obj[key];
			return res;
		}, {});
};

/**
 * Filters an array of objects to remove duplicates based on their stringified representation.
 * @param arr The array to filter.
 * @returns The filtered array.
 */
export const uniqObjectArray = (arr: any[]) =>
	arr.filter(
		(element, index) =>
			arr.findIndex(
				step => JSON.stringify(element) === JSON.stringify(step)
			) === index
	);

/**
 * Returns the object in the collection with the minimum value of the specified key.
 * @param collection - The collection to search.
 * @param key - The key to compare values.
 * @returns The object with the minimum value of the specified key.
 */
export const minBy = (collection: any[], key: string) => {
	const select = (a: any, b: any) => (a[key] <= b[key] ? a : b);
	return collection.reduce(select, {});
};

/**
 * Returns the object in the collection with the maximum value for the specified key.
 * @param collection - The collection to search.
 * @param key - The key to compare values.
 * @returns The object with the maximum value for the specified key.
 */
export const maxBy = (collection: any[], key: string) => {
	const select = (a: any, b: any) => (a[key] >= b[key] ? a : b);
	return collection.reduce(select, {});
};

/**
 * Returns the URL of a bank logo based on its UUID.
 * @param uuid - The UUID of the bank logo.
 * @returns The URL of the bank logo.
 */
export const bankLogoUrl = (uuid: string): string => {
	return `${environment.biAPIUrl}/logos/${uuid}-thumbnail.webp`;
};

/**
 * Removes all properties with null or undefined values from an object.
 * @param obj - The object to remove empty properties from.
 * @returns A new object with all empty properties removed.
 */
export const removeEmpty = (obj: any): any => {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};
