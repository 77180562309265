import { createFeatureSelector, createSelector } from "@ngrx/store";
import { filterAccountType } from "@src/app/core/services/utils/utils.service";
import { AccountTypeName, Contract } from "../../interface";
import { ContractState, adapter } from "./contract.reducers";

// contract selectors
const ContractSelectors = createFeatureSelector<ContractState>("contracts");

export const { selectIds, selectEntities, selectAll, selectTotal } =
	adapter.getSelectors(ContractSelectors);

export const displayContracts = createSelector(
	selectAll,
	(contracts: Contract[]) =>
		contracts.filter(
			(contract: Contract) =>
				contract.isActiveContract &&
				contract.type &&
				filterAccountType(contract.type as AccountTypeName)
		)
	// .filter((c: any) => !!c.ContractLastUpdate?.tri?.tauxAnnuel)
);

export const contractsByConnectionId = (id: string) =>
	createSelector(selectAll, (contracts: Contract[]) =>
		contracts.filter(
			(contract: Contract) => contract.connectionId === parseInt(id)
		)
	);

export const contractById = (id: string) =>
	createSelector(selectEntities, contracts => contracts[id]);

export const contractValuesById = (id: string) =>
	createSelector(
		ContractSelectors,
		(state: ContractState) => state.contractValues[id]
	);

export const contractsLoading = createSelector(
	ContractSelectors,
	(state: ContractState) => state.loading
);
export const contractsError = createSelector(
	ContractSelectors,
	(state: ContractState) => state.error
);
