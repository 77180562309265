import { BoConnection } from "@interfaces";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { actions as connectionActions } from "./connection.actions";

export interface ConnectionState extends EntityState<BoConnection> {
	error: any;
	loading: boolean;
}
const selectId = (c: BoConnection): string => c.id;

export const adapter: EntityAdapter<BoConnection> =
	createEntityAdapter<BoConnection>({
		selectId: selectId,
		sortComparer: false
	});
const initialState: ConnectionState = adapter.getInitialState({
	error: null,
	loading: false
});

export const connectionReducer = createReducer(
	initialState,
	on(connectionActions.loadConnections, state => ({ ...state, loading: true })),
	on(connectionActions.loadConnectionsSuccess, (state, { connections }) => {
		state = { ...state, loading: false };
		return adapter.upsertMany(connections, state);
	}),
	on(connectionActions.updateConnectionSuccess, (state, { update }) =>
		adapter.updateOne(update, state)
	)
);
